
export default [
    {
        path: '/earnout',
        component: "__base",
        children: [
          {
            // module: "EARNOUT",
            path: "project",
            component: "ProjectList.vue",
            meta: { title: "Project List" },          
          },
          {
            // module: "EARNOUT",
            path: "project/view/:id",
            component: "ProjectView.vue",
            meta: { title: "Project Details" },        
          },
          {
            // module: "EARNOUT",
            path: "project/assignment/:id",
            component: "AssignmentView.vue",
            meta: { title: "Earnout Details" },        
          },
          {
            // module: "EARNOUT",
            path: "*",
            component: "",
          },
        ],
    },
  ]
  
  
  